import * as $ from 'jquery';
import axios from 'axios';

class NewDayEstimates{

    constructor(){
        console.log('New Day Estimates');

        this.hideCoverageFields();
        $('.start').show();

        this.bindEvents();
    }

    bindEvents(){

        $('select').on('change', (event) => {
            $('.estimate-result').hide();

            $(event.currentTarget).removeClass('invalid');
            $(event.currentTarget).parent().find('.invalid').hide();
        });

        $('input[type=radio]').on('click', (event) => {
            $(event.currentTarget).parent().parent().find('.invalid').hide();
        });

        $('#coverage').on('change', (event) => {

            let val = $(event.currentTarget).val();

            $('a.startover').show();

            this.resetCoverageFields();
            this.hideCoverageFields();
            $('div.invalid').hide();
            $('select').removeClass('invalid');
            $('p.invalid').hide();

            $('input[type=radio]').prop('checked', false);
            $('select').val(null);
            $('#coverage').val(val);


            if(val === 'CPL'){
                $('.CPL').show();

                if( $('#est_value_id').val() === '7')
                    $('#est_value_id').val(null);

                $('#est_value_id option').eq(6).hide(); //Up to $5M

                $('#est_limit_id option').eq(6).hide();  // 6M
                $('#est_limit_id option').eq(7).hide();  // 7M
                $('#est_limit_id option').eq(8).hide();  // 8M
                $('#est_limit_id option').eq(9).hide();  // 9M
                $('#est_limit_id option').eq(10).hide();  //10M
            }

            if(val === 'CPrL'){
                $('.CPrL').show();

                $('#est_blanket_id option').eq(0).hide(); //null
                $('#est_blanket_id option').eq(2).hide(); //null
                $('#est_blanket_id').val(2);

                $('#est_limit_id option').eq(6).hide();  //6M
                $('#est_limit_id option').eq(7).hide();  //7M
                $('#est_limit_id option').eq(8).hide();  //8M
                $('#est_limit_id option').eq(9).hide();  //9M
                $('#est_limit_id option').eq(10).hide(); //10M

                //$('#est_retention_id option').eq(1).hide() //10K

                $('#est_value_id option').eq(1).hide();  //Under 1
                $('#est_value_id option').eq(2).hide();  //1-2
                $('#est_value_id option').eq(3).hide();  //2-3
                $('#est_value_id option').eq(4).hide();  //3-4
                $('#est_value_id option').eq(5).hide();  //4-5

                $('#est_contractor_id option').eq(2).hide();  //demolition
                $('#est_contractor_id option').eq(10).hide(); //oil & gas
                $('#est_contractor_id option').eq(11).hide(); //painting
            }

            if(val === 'PLL'){
                $('.PLL').show();

                $('#est_term_id option').eq(0).hide();  // 1 year
                $('#est_term_id option').eq(1).hide();  // 1 year
                $('#est_term_id option').eq(2).hide();  // 2 years
                $('#est_term_id option').eq(4).hide();  // 4 years
                $('#est_term_id option').eq(5).hide();  // 5 years

                $('#est_term_id').val(4);

                $('#est_retention_id option').eq(1).hide();  //10000
            }
        });

        $('#est_blanket_id').on('change', (event)=> {

            let val = $(event.currentTarget).val();

            if(val === '2') {
                $('div.est_term_id').hide();
                $('div.est_completed_operation_id').hide();
            }

            if(val === '3'){
                $('div.est_term_id').show();
                $('div.est_completed_operation_id').show();
            }
        });

        $('#est_limit_id').on('change', (event)=> {

            let val = $(event.currentTarget).val()*1;
            let val2 = $('#coverage').val();

            if(val > 3 && (val2 === 'CPL' || val2 === 'CPrL')) {
                $('#est_retention_id option').eq(1).hide(); //10000
                $('#est_retention_id').val(null);
            } else if(val2 !== 'PLL'){
                $('#est_retention_id option').eq(1).show();
            }
        });

        $('form#estimates').on('submit', (event) => {

            event.preventDefault();

            if( !this.checkForm() ){

                return false;
            }

            let val = $('#coverage').val();
            let val2 = $('#est_blanket_id').val();



            let form_data = new FormData();

            if(val === 'CPL' || val === 'CPrL'){
                form_data.append('est_blanket_id', $('#est_blanket_id').val());
            }
            if(val === 'CPL' || val === 'CPrL' || val === 'PLL'){
                form_data.append('coverage', $('#coverage').val());
            }
            if(val === 'CPL' || val === 'CPrL'){
                form_data.append('est_mold_id', $('#est_mold_id').val());
            }
            if(val === 'CPL'){
                form_data.append('est_environmental_id', $('#est_environmental_id').val());
            }
            if(val === 'CPrL'){
                form_data.append('est_design_build_id', $('#est_design_build_id').val());
            }
            if(val === 'PLL'){
                form_data.append('est_occupancy_id', $('input[name=est_occupancy_id]:checked').val());
            }
            if((val === 'CPL' && val2 === '3') || val === 'PLL'){
                form_data.append('est_term_id', $('#est_term_id').val());
            }
            if(val === 'CPL' && val2 === '3'){
                form_data.append('est_completed_operation_id', $('#est_completed_operation_id').val());
            }
            if(val === 'CPL' || val === 'CPrL' || val === 'PLL'){
                form_data.append('est_limit_id', $('#est_limit_id').val());
            }
            if(val === 'CPL' || val === 'CPrL' || val === 'PLL'){
                form_data.append('est_retention_id', $('#est_retention_id').val());
            }
            if(val === 'CPL' || val === 'CPrL'){
                form_data.append('est_value_id', $('#est_value_id').val());
            }
            if(val === 'CPL' || val === 'CPrL'){
                form_data.append('est_contractor_id', $('#est_contractor_id').val());
            }
            if(val === 'PLL'){
                form_data.append('est_square_footage_id', $('#est_square_footage_id').val());
            }

            form_data.append('insured_name', $('#insured_name').val());
            form_data.append('agent_name', $('#agent_name').val());
            form_data.append('agency_name', $('#agency_name').val());
            form_data.append('agency_location', $('#agency_location').val());
            form_data.append('comments', $('#comments').val());

            if(document.getElementById('logo').files[0])
                form_data.append('logo', document.getElementById('logo').files[0] );

            axios.post('/getEstimate', form_data)
                .then((response) => {

                    console.log(response)

                    if(response.status === 200) {


                        if(response['data'] === 'bad image'){

                            $('div.logo .invalid').show();
                            $([document.documentElement, document.body]).animate({
                                scrollTop: $('.logo').offset().top
                            }, 500);

                             return false;
                        }
                        $('.estimate-result').show();

                        if(response['data']['estimate_avail'] === 0) {
                            $('.export').hide();
                            $('.preliminary-message').hide();
                        } else {
                             $('.export').show();
                             $('.preliminary-message').show();
                        }

                        if(response['data']['coverage'] === 'PLL'){
                            $('#matrix').prop('checked', false);
                            $('.matrix').hide();
                            $('#rating_based_on').show();
                        } else {
                            $('.matrix').show();
                            $('#rating_based_on').hide();
                        }

                        $('#estimate-quote').text(response['data']['estimate']);
                        $('#estimate-pdf').attr('data-id', response['data']['estimate_id']);

                        this.hideCoverageFields()
                        if(val === 'CPL'){
                            $('.CPL').show();
                        }else if(val === 'CPrL'){
                            $('.CPL').show();
                        }else if(val === 'PLL'){
                            $('.PLL').show();
                        }

                        //files

                       $('#product_profile').hide();
                       $('#env_risk_profile').hide();
                       $('#claim_examples').hide();


                       $('#product_profile ul').html('');
                       $('#env_risk_profile ul').html('');
                       $('#claim_examples ul').html('');

                        if(response['data']['product_files']){
                            for(let i=0; i<response['data']['product_files'].length; i++) {
                                $('#product_profile ul').append('<li><a target="_blank" href="'+response['data']['product_files'][i]['file']+'">'+response['data']['product_files'][i]['name']+'</a></li>');
                                 $('#product_profile').show();
                            }
                        }

                        if(response['data']['enivornemntal_risk_files']){
                            for(let i=0; i<response['data']['enivornemntal_risk_files'].length; i++) {
                                $('#env_risk_profile ul').append('<li><a  target="_blank" href="'+response['data']['enivornemntal_risk_files'][i]['file']+'">'+response['data']['enivornemntal_risk_files'][i]['name']+'</a></li>');
                                $('#env_risk_profile').show();
                            }
                        }

                       if(response['data']['links']){
                            for(let i=0; i<response['data']['links'].length; i++) {
                                $('#claim_examples ul').append('<li><a  target="_blank" href="'+response['data']['links'][i]['link']+'">'+response['data']['links'][i]['name']+'</a></li>');
                                $('#claim_examples').show();
                            }

                       }

                       if(response['data']['lessthan4k']){
                            $("#lessthan4k").show();
                       } else {
                            $("#lessthan4k").hide();
                       }



                        $([document.documentElement, document.body]).animate({
                            scrollTop: $('.estimate-result').offset().top
                        }, 500);
                    }

            });
        });

        $('#estimate-pdf').on('click', (event) => {

            let matrix;

            if( $('#matrix').prop('checked') ){
                matrix = 1;
            } else {
                matrix = 0;
            }
            window.open('/export-pdf/'+$(event.currentTarget).attr('data-id')+'/'+matrix);
            return false;
        });

        $('.email button').on('click', (event) => {

            $('.email button').prop('disabled', true);

            if( this.validateEmail( $('#email').val() ) ){

                let matrix = 0;

                if( $('#matrix').prop('checked') ){
                    matrix = 1;
                } else {
                    matrix = 0;
                }

                let form_data = new FormData();
                form_data.append('email', $('#email').val());
                form_data.append('estimate', $('#estimate-pdf').attr('data-id'));
                form_data.append('matrix', matrix);

                axios.post('/emailEstimate', form_data)
                .then((response) => {

                     $('.email button').prop('disabled', false);

                    if(response.status === 200) {
                        $('p.response').text(response['data']);
                    } else {
                       $('p.response').text('There was an error, please try again');
                    }
                });
            } else {
                $('p.response').text('Please enter a valid email address.');
                 $('.email button').prop('disabled', false);
            }

            return false;
        });

    }

    hideCoverageFields(){

        $('.CPL').hide();
        $('.CPrL').hide();
        $('.PLL').hide();

    }

    resetCoverageFields() {

        $('.CPL').show();
        $('.CPrL').show();
        $('.PLL').show();

        $('option').show();
    }

    checkForm() {

        let errors = false;
        let val = $('#coverage').val();
        let val2 = $('#est_blanket_id').val();


        $('div.invalid').hide();
        $('select').removeClass('invalid');
        $('p.invalid').hide();

        if( val === '') {
            errors = true;
            $('div.coverage .invalid').show();
        }
        else if(val === 'CPL') {

            if( $('#est_blanket_id').val() === '' ){
                errors = true;
                $('#est_blanket_id').addClass('invalid');
                $('div.est_blanket_id .invalid').show();
            }

            if( $('#est_environmental_id').val() === '' ){
                errors = true;
                $('#est_environmental_id').addClass('invalid');
                $('div.est_environmental_id .invalid').show();
            }

            if( $('#est_limit_id').val() === '' ){
                errors = true;
                $('#est_limit_id').addClass('invalid');
                $('div.est_limit_id .invalid').show();
            }

            if( $('#est_retention_id').val() === '' ){
                errors = true;
                $('#est_retention_id').addClass('invalid');
                $('div.est_retention_id .invalid').show();
            }

            if( $('#est_value_id').val() === '' ){
                errors = true;
                $('#est_value_id').addClass('invalid');
                $('div.est_value_id .invalid').show();
            }

            if( $('#est_contractor_id').val() === '' ){
                errors = true;
                $('#est_contractor_id').addClass('invalid');
                $('div.est_contractor_id .invalid').show();
            }

            if(val2 === '3') {
                if( $('#est_term_id').val() === '' ){
                     errors = true;
                    $('#est_term_id').addClass('invalid');
                    $('div.est_term_id .invalid').show();
                }
            }
        }
        else if(val === 'CPrL') {

            if( $('#est_blanket_id').val() === '' ){
                errors = true;
                $('#est_blanket_id').addClass('invalid');
                $('div.est_blanket_id .invalid').show();
            }

            if( $('#est_design_build_id').val() === '' ){
                errors = true;
                $('#est_design_build_id').addClass('invalid');
                $('div.est_design_build_id .invalid').show();
            }

            if( $('#est_limit_id').val() === '' ){
                errors = true;
                $('#est_limit_id').addClass('invalid');
                $('div.est_limit_id .invalid').show();
            }

            if( $('#est_retention_id').val() === '' ){
                errors = true;
                $('#est_retention_id').addClass('invalid');
                $('div.est_retention_id .invalid').show();
            }

            if( $('#est_value_id').val() === '' ){
                errors = true;
                $('#est_value_id').addClass('invalid');
                $('div.est_value_id .invalid').show();
            }

            if( $('#est_contractor_id').val() === '' ){
                errors = true;
                $('#est_contractor_id').addClass('invalid');
                $('div.est_contractor_id .invalid').show();
            }

        }
        else if(val === 'PLL') {

            if( !$('input[name=est_occupancy_id]:checked').val()){
                errors = true;
                $('div.est_occupancy_id .invalid').show();
            }

            if( $('#est_limit_id').val() === '' ){
                errors = true;
                $('#est_limit_id').addClass('invalid');
                $('div.est_limit_id .invalid').show();
            }

            if( $('#est_retention_id').val() === '' ){
                errors = true;
                $('#est_retention_id').addClass('invalid');
                $('div.est_retention_id .invalid').show();
            }

            if( $('#est_square_footage_id').val() === '' ){
                errors = true;
                $('#est_square_footage_id').addClass('invalid');
                $('div.est_square_footage_id .invalid').show();
            }
        }

        if(errors) {
            $('p.invalid').show();
            $('div.estimate-result').hide();

            $([document.documentElement, document.body]).scrollTop($(document).height());

             return false;
        }

        return true;
    }

    validateEmail(sEmail) {
        let filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        if (filter.test(sEmail)) {
            return true;
        }

        return false;
    }


}

export default NewDayEstimates;

